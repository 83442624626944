import axios from 'axios'

import { createCrispChat } from './helpers'

export const getUsageLimit = async (userId) => {
  //Get current usage
  try {
    const res = await axios.post(`/api/usage/monthly-limit`, { userId: userId })
    return [res.data.usage, null]
  } catch (e) {
    return [null, e]
  }
}

export const updateUsage = async (userId, usagePercent) => {
  //Update usage record
  try {
    const res = await axios.post(`/api/usage/update-usage`, {
      userId: userId,
      usagePercent: usagePercent
    })
    return [res.data, null]
  } catch (e) {
    return [null, e]
  }
}

export const initializeMisc = (authUser, userData) => {
  //For gtag
  window.gtag('set', {
    user_id: authUser.id
  })

  window.plUser.id = authUser.id
  window.plUser.admin = userData.admin

  //Create crisp chat
  createCrispChat(userData)

  //Create ytag
}
