import { useTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

function BlockedUserNotice({ userId }) {
  const blockedUsers = ['C7Yea56U1iTn6HJp8ISoUeOJa682']
  const { t } = useTranslation()

  if (blockedUsers.includes(userId)) {
    return (
      <Alert color={'danger'} className="m-0">
        <span className="block sm:inline">
          {t(
            'Due to high traffic and no active popups your account has been paused. Please contact live chat to re-start the account.'
          )}
        </span>
      </Alert>
    )
  }

  return null
}

export default BlockedUserNotice
