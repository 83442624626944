import createApp from '@shopify/app-bridge'
import { authenticatedFetch } from '@shopify/app-bridge/utilities'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import InnerModal from '../components/common/InnerModal'
import NotEnabledBar from '../components/common/NotEnabledBar'
import UpgradeModal from '../components/common/UpgradeModal'
import InstallationModal from '../components/integration-components/InstallationModal'
import { msClarityEvent, posthogEvent, sendToLogDNA } from '../lib/helpers'
import PlatformHelperFactory from '../utils/PlatformHelper'
import { purgeCache } from '../utils/common'
import { useIdle } from '../utils/hooks/idle'
import { useAuth } from './AuthProvider'
import { useFirebase } from './FirebaseProvider'
import { useModal } from './ModalProvider'

const PlatformContext = createContext()
let timeout

const getShopifyApp = (host) => {
  const config = {
    apiKey: process.env.NEXT_PUBLIC_SHOPIFY_CLIENT_ID,
    host: host,
    forceRedirect: true
  }
  try {
    const app = createApp(config)
    return app
  } catch (e) {
    console.log(e)
  }
}

const getApiCall = (authenticated, app = null, userData = null) => {
  if (authenticated) {
    return async function (topic, payload) {
      const body = { topic, userId: userData.userId, ...payload }
      const response = await authenticatedFetch(app)('/api/shopify/apiCall', {
        method: 'POST',
        body: JSON.stringify(body)
      })
      return response.ok ? await response.json() : null
    }
  } else {
    return async function (topic, payload) {
      const body = {
        topic,
        noSessionToken: true,
        userId: userData.userId,
        ...payload
      }
      const response = await window.fetch('/api/shopify/apiCall', {
        method: 'POST',
        body: JSON.stringify(body)
      })
      return response.ok ? await response.json() : null
    }
  }
}

const PlatformProvider = ({ children }) => {
  const { userData } = useAuth()
  const { t } = useTranslation()
  const { showModal, hideModal } = useModal()
  const router = useRouter()
  const { isIdle } = useIdle()
  const [shopifyApp, setShopifyApp] = useState(null)
  const [shopifyApiCall, setShopifyApiCall] = useState(new Function())
  const [modal, setModal] = useState(false)
  const { firebase } = useFirebase()
  const toggleInstallationModal = () => setModal(!modal)
  const [appReady, setAppReady] = useState(false)
  const [platformHelper, setPlatformHelper] = useState(null)
  const [shopifyEmbedConfig, setShopifyEmbedConfig] = useState(null)

  const platformInit = () => {
    setAppReady(() => {
      setPlatformHelper(new PlatformHelperFactory({ userData }))
      return true
    })
  }

  const shopifyInit = (host) => {
    setAppReady(() => {
      if (host) {
        const app = getShopifyApp(host)
        if (app) {
          setShopifyApp(app)
          const apiCall = getApiCall(true, app, userData)
          setShopifyApiCall(() => apiCall)
          setShopifyEmbedConfig({ host, shop: router.query.shop })
        } else {
          sendToLogDNA(`Failed to create Shopify app for ${userData.userId}`)
        }
      } else {
        const apiCall = getApiCall(false, null, userData)
        setShopifyApiCall(() => apiCall)
      }
      setPlatformHelper(new PlatformHelperFactory({ userData }))
      return true
    })
  }

  const goPricePage = async () => {
    const url = platformHelper.getPricingUrl()

    if (!url) return

    posthogEvent({ eventType: 'capture', key: 'view_pricing_page' })

    if (url.match(/^http/)) window.open(url, '_self')
    else await router.push(url)
  }

  const hideNotEnabledBar = () => {
    return !router.pathname.match(/\/displays$/)
  }

  const showUpgradeModal = () => {
    showModal({
      hideFooter: true,
      hideHeader: true,
      customPadding: '0 30px',
      innerComponent: (
        <UpgradeModal hideModal={hideModal} platform={platformHelper} />
      )
    })
  }

  const initiatePlatform = async () => {
    if (!userData) return

    if (!appReady) {
      msClarityEvent({ eventType: 'identify', value: userData.userId })
      msClarityEvent({
        eventType: 'set',
        key: 'platform',
        value: userData?.platform?.name.toLowerCase()
      })
      msClarityEvent({
        eventType: 'set',
        key: 'plan',
        value: userData?.platform?.currentPlan.toLowerCase()
      })
      msClarityEvent({
        eventType: 'set',
        key: 'platformPlan',
        value: userData?.platformPlan ?? ''
      })
      posthogEvent({
        eventType: 'identify',
        key: userData.userId,
        value: {
          email: userData?.email ?? '',
          plan: userData?.platform?.currentPlan.toLowerCase(),
          platform: userData?.platform?.name.toLowerCase(),
          platformPlan: userData?.platformPlan ?? '',
          staffAccount: userData?.staffAccount === true
        }
      })
      if (!userData.trackingEvents?.signedUp) {
        await firebase.updateUserData({
          ...userData,
          trackingEvents: {
            ...userData?.trackingEvents,
            signedUp: true
          }
        })
        posthogEvent({ eventType: 'capture', key: 'sign_up_v1' })
      }
      if (userData.platform.name === 'shopify') {
        let host = ''
        if (router.query.host) {
          sessionStorage.setItem('shopifyHost', router.query.host)
          host = router.query.host
        } else if (sessionStorage.getItem('shopifyHost')) {
          host = sessionStorage.getItem('shopifyHost')
        }

        shopifyInit(host)
      } else {
        platformInit()
      }
    }
  }

  const welcomeProcess = async () => {
    if (!appReady) return

    if (!Cookies.get('welcome-finished')) {
      if (platformHelper.isAppEnabled()) return

      if (platformHelper.type === 'shopify') {
        !hideNotEnabledBar() && toggleInstallationModal()
      } else if (platformHelper.type === 'wix') {
        const [success, error] = await platformHelper.enableScript()

        if (error) {
          const errorMsg = error.response?.data?.error ?? ''
          if (errorMsg.includes('connected domain')) {
            alert(
              t(
                'Embedded script apps can only be added by the owners of premium Wix sites with connected domains.'
              )
            )
          } else if (errorMsg.includes('refresh token')) {
            alert(t('If this error continues please contact support here.'))
          }
        }

        userData.platform.appEmbed = !!success
        await firebase.updateUserData(userData)
      }
      Cookies.set('welcome-finished', '1')
    }
  }

  //Init the platform
  useEffect(() => {
    initiatePlatform()
  }, [router.query.host, userData])

  //Welcome process
  useEffect(() => {
    welcomeProcess()
  }, [appReady])

  useEffect(() => {
    timeout = setInterval(() => {
      if (isIdle || !platformHelper) return

      platformHelper
        .sync()
        .then((result) => {
          if (result.status === 'changed') {
            //UserData automatically changed by the listener, but we need to update the platformHelper
            setPlatformHelper(
              new PlatformHelperFactory({ userData: result.userData })
            )
            purgeCache(userData.userId, false, true)
          }
        })
        .catch((e) => console.error(e))
    }, 10000)

    return () => clearInterval(timeout)
  }, [isIdle, platformHelper])

  return (
    <PlatformContext.Provider
      value={{
        appReady,
        shopifyApp,
        shopifyApiCall,
        toggleInstallationModal,
        platform: platformHelper,
        showUpgradeModal,
        goPricePage,
        shopifyEmbedConfig
      }}
    >
      <InnerModal modal={modal} toggle={toggleInstallationModal}>
        <InstallationModal
          userData={userData}
          hideModal={toggleInstallationModal}
        />
      </InnerModal>
      {!hideNotEnabledBar() && platformHelper?.isAppEnabled() === false && (
        <NotEnabledBar />
      )}
      {children}
    </PlatformContext.Provider>
  )
}

const usePlatform = () => {
  return useContext(PlatformContext)
}

export { PlatformProvider, usePlatform }
