import { useRouter } from 'next/router'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import FullPageSkeleton from '../components/FullPageSkeleton'
import { getPlanWorkspace } from '../lib/helpers'
import { sendToLogDNA } from '../lib/helpers'
import { useAuth } from './AuthProvider'
import { useFirebase } from './FirebaseProvider'
import { usePlatform } from './PlatformProvider'

const WorkspaceContext = createContext()

let oldWorkspace = null

const WorkspaceProvider = ({ children }) => {
  const router = useRouter()

  const { appReady } = usePlatform()
  const { userData, currentPlan } = useAuth()
  const { firebase, Workspace, Display } = useFirebase()
  const [loading, setLoading] = useState(true)
  const [displays, setDisplays] = useState([])

  const [reRender, setReRender] = useState(0)
  const [workspaces, setWorkspaces] = useState([])
  const [currentWorkspace, _setCurrentWorkspace] = useState(null)

  const workspaceLimit = getPlanWorkspace(currentPlan)
  const { pathname } = useRouter()
  const hideSkelenton = () => {
    return pathname.match(/\/displays\/+/)
  }

  const hasPermissionTo = (subject) => {
    return subject.workspace === currentWorkspace.id
  }
  const setCurrentWorkspace = (workspace) => {
    oldWorkspace = currentWorkspace
    _setCurrentWorkspace(workspace)
    localStorage.setItem(`current-workspace-${userData.userId}`, workspace.id)
  }

  const sendInvitation = async (email, _successCallback, _errorCallback) => {
    const [_result, error] = await firebase.sendRegisterLink(
      email,
      currentWorkspace.id
    )
    if (!error) {
      if (!Array.isArray(currentWorkspace.members)) {
        currentWorkspace.memberEmails = []
        currentWorkspace.members = []
      }
      if (!currentWorkspace.memberEmails.includes(email)) {
        currentWorkspace.memberEmails.push(email)
        currentWorkspace.members.push({ email, role: 'member' })
      }

      const updated = await Workspace.update(currentWorkspace)
      setCurrentWorkspace({ ...updated })
      return [currentWorkspace, null]
    } else {
      return [error, null]
    }
  }

  const isWorkspaceOwner = () => {
    return currentWorkspace.userId === userData.userId
  }

  const fetchWorkspaces = async () => {
    if (!appReady) return

    let current
    let workspaces = await Workspace.getAllInUser(userData.userId)

    //Merge invited workspaces
    if (userData.invitedWorkspaces?.length > 0) {
      const invited = await Workspace.getInvited(
        userData.invitedWorkspaces,
        userData.userId
      )
      workspaces = [...workspaces, ...invited]
    }

    if (workspaces.length > 0) {
      const currentWorkspaceId =
        localStorage.getItem(`current-workspace-${userData.userId}`) ||
        localStorage.getItem('current-workspace')
      if (currentWorkspaceId) {
        current =
          workspaces.find((workspace) => workspace.id === currentWorkspaceId) ||
          workspaces[0]
      } else {
        current = workspaces[0]
      }
    } else {
      sendToLogDNA(`Failed to getAllInworkspace: ${userData.userId}`)
      window.alert(
        'Something went wrong, please contact support. hello@promolayer.io'
      )
    }
    if (current) {
      firebase.setWorkspace(current)
      setWorkspaces(workspaces)
      setCurrentWorkspace(current)
    }
  }

  useEffect(() => {
    if (appReady) fetchWorkspaces()
  }, [appReady])

  useEffect(() => {
    if (!currentWorkspace || !workspaces.length) return
    if (pathname === '/displays') {
      getAllInWorkspace()
        .then((displays) => {
          setDisplays(displays)
          setLoading(false)
        })
        .catch()
    } else {
      setLoading(false)
    }
  }, [currentWorkspace, workspaces, pathname])

  const getAllInWorkspace = useCallback(async () => {
    try {
      const displays = await Display.getAllInWorkspace(currentWorkspace.id)
      return displays
    } catch (e) {
      sendToLogDNA(`Failed to get getAllInworkspace: ${e.message}`)
    }
  }, [currentWorkspace])

  return loading ? (
    <FullPageSkeleton hide={hideSkelenton()} />
  ) : (
    <WorkspaceContext.Provider
      value={{
        displays,
        setDisplays,
        currentWorkspace,
        setCurrentWorkspace,
        workspaces,
        setWorkspaces,
        sendInvitation,
        hasPermissionTo,
        isWorkspaceOwner,
        workspaceLimit
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}

const useWorkspace = () => useContext(WorkspaceContext)
export { WorkspaceProvider, useWorkspace }
