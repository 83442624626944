import Image from 'next/image'
import { useTranslation } from 'react-i18next'

import { posthogEvent } from '../../lib/helpers'

const UpgradeModal = ({ platform, hideModal }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="align-items-center relative p-2">
        <Image
          src={'/assets/images/upgrade-modal/left-top-vector.svg'}
          height={35}
          width={35}
          alt="vector"
          style={{ position: 'absolute', top: '100px', left: '-50px' }}
        />
        <Image
          src={'/assets/images/upgrade-modal/left-bottom-vector.svg'}
          height={65}
          width={65}
          alt="vector"
          style={{ position: 'absolute', bottom: '-48px', left: '-39px' }}
        />
        <Image
          src={'/assets/images/upgrade-modal/right-bottom-vector.svg'}
          height={43}
          width={43}
          alt="vector"
          style={{ position: 'absolute', bottom: '100px', right: '-37px' }}
        />
        <Image
          src={'/assets/images/upgrade-modal/right-top-vector.svg'}
          height={43}
          width={43}
          alt="vector"
          style={{ position: 'absolute', top: '-30px', right: '-45px' }}
        />
        <div className="main-vector-wrapper">
          <Image
            width={736}
            height={486}
            src={'/assets/images/upgrade-modal/up-vector-high-five.svg'}
            style={{
              position: 'absolute',
              margin: '0 auto',
              height: 'auto',
              width: '80%',
              transform: ' translateY(-70px)'
            }}
          />
        </div>
        <div className="unlock-sub-box">
          <Image
            src={'/assets/images/crown.svg'}
            width={15}
            height={15}
            alt="crown image"
          />
          <p className="mb-0 unlock-sub-txt">
            {t('Upgrade to unlock this feature')}
          </p>
        </div>
        <h3 className="mb-0 title-txt">{t('Do more with Promolayer')}</h3>
        <p
          className="mt-4 para-text"
          dangerouslySetInnerHTML={{
            __html: t(
              '<strong>Supercharge Promolayer</strong> and unlock advanced conversion-rate boosting features such as A/B testing, email auto-responders, notifications, webhook integration and much more'
            )
          }}
        />
        <div className="mt-4 btn-wrapper">
          <a
            className="btn btn-primary btn-lg w-100"
            target={platform.type === 'shopify' ? '_self' : '_blank'}
            href={platform.getPricingUrl()}
            onClick={() =>
              posthogEvent({ eventType: 'capture', key: 'view_pricing_page' })
            }
          >
            {t('See features & pricing')}
          </a>
          <p className="mb-0 outline-btn" onClick={hideModal}>
            {t('I’ll do it later')}
          </p>
        </div>
      </div>
      <style jsx>{`
        .main-vector-wrapper {
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          height: 150px;
        }
        .unlock-sub-box {
          display: flex;
          gap: 6px;
          align-items: center;
        }
        .unlock-sub-txt {
          color: rgba(102, 102, 102, 1);
          font-weight: 600;
          font-size: 16px;
        }
        .title-txt {
          color: rgba(51, 51, 51, 1);
          font-weight: 700;
          font-size: 24px;
        }
        .mt-32 {
          margin-top: 32px;
        }
        .para-text {
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
        }
        .btn-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          justify-content: center;
          align-items: center;
        }
        .outline-btn {
          color: rgba(34, 34, 34, 1);
          text-decoration: underline;
          cursor: pointer;
          font-size: 16px;
          width: fit-content;
        }
      `}</style>
    </>
  )
}

export default UpgradeModal
